import { template } from "lodash";

export const unconfiguredInsightTemplate = template(
  "<%= i_name %> more info coming soon!"
);

const ML_UPDATED_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>`; // https://icons.getbootstrap.com/icons/check-circle/

export default {
  insights: {
    no_tech_dna: {
      summary: template("No tech DNA"),
      description: template("No tech people found in DIG"),
      section: "people",
      icon: "bi-node-minus",
    },
    not_using_tech: {
      summary: template("Not using Tech"),
      description: template("Not using tech"),
      section: "product",
      icon: "bi-node-minus",
    },
    worked_at_top_tech: {
      summary: template("Worked at Top Tech"),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> was <% if (role !== "[role]") { %> <%= role %> <% } else { %> <% } %> at <% if (typeof o_id === "string") { %> <a href="/organizations/<%= o_id %>" target="_blank"><%= o_name %></a> <% } else { %> <%= o_name %> <% } %> <%= top_tech_reason %> for <%= duration %> years <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "people",
      icon: "bi-person-check",
    },
    founders_commitment_past_job: {
      summary: template("Lack of commitment"),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> still working <% if (role !== "[role]") { %> as <%= role %> <% } else { %> <% } %> at <%= o_name %>`
      ),
      section: "people",
      icon: "bi-person-plus",
    },
    founders_commitment_new_job: {
      summary: template("Lack of commitment"),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> started new role <% if (role !== "[role]") { %> as <%= role %> <% } else { %> <% } %> at <%= o_name %>`
      ),
      section: "people",
      icon: "bi-person-plus",
    },
    has_previous_exit: {
      summary: template("Previous exits"),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> exited <%= o_name %> <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "people",
      icon: "bi-box-arrow-left",
    },
    has_previous_exit_in_bio: {
      summary: template("Previous exit"),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> mentions exit in his bio: <%= text %> <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "people",
      icon: "bi-box-arrow-left",
    },
    good_education: {
      summary: template("Good Education"),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> studied at <%= school_names %>`
      ),
      section: "people",
      icon: "bi-patch-check",
    },
    top_tier_follow_on: {
      summary: template("Interesting Investors"),
      description: template(
        `<%= percent %>% of <%= inv_name %>'s investments got <a href="/investors/<%= inv_id %>/portfolio?ids=<%= follow_on_ids.join(',') %>&investor_ids=<%= inv_id %>">follow-on</a> by TopTier VCs <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "funding",
      icon: "bi-person-badge",
    },
    timing_of_fundraising: {
      summary: template("May be looking for cash"),
      description: template(
        `<% if (typeof adjective !== 'undefined') {%> <%= adjective %> timing to engage: <%} else {%> <%} %> <%if (delta_time_months !== 0) {%> <%= delta_time_months %> months <%} else {%> less than a month <%} %>  since raised <%= f_amount.toLocaleString() %>$`
      ),
      section: "funding",
      icon: "bi-piggy-bank",
    },
    capital_performance: {
      summary: template(`<%= adjective %> Timing`),
      description: template(
        `<%= adjective %> timing, funding <%= funding %> and <%= traffic_class %>`
      ),
      section: "funding",
      icon: "bi-calendar-check",
    },
    website_not_localized: {
      summary: template(`Website not Localized`),
      description: template(`Website not Localized`),
      section: "market",
      icon: "bi-flag",
    },
    toptier_investor: {
      summary: template(`TopTier Investors`),
      description: template(
        `<a href="/investors/<%= inv_id %>" target="_blank"><%= inv_name %></a> is a TopTier Vc <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "funding",
      icon: "bi-sunglasses",
    },
    trend_insights: {
      summary: template(`<%= signal_name %>: <%= traffic_class %>`),
      description: template(`<%= signal_name %>: <%= traffic_class %>`),
      section: "product",
      icon: "bi-graph-up-arrow",
    },
    not_interesting_space: {
      summary: template(`Not Interesting Space (beta)`),
      description: template(`Not Interesting Space (beta)`),
      section: "product",
      icon: "bi-hand-thumbs-down",
    },
    interesting_space: {
      summary: template(`Interesting Space (beta)`),
      description: template(`Interesting Space (beta)`),
      section: "product",
      icon: "bi-hand-thumbs-up",
    },
    not_relevant_space: {
      summary: template(`Not Relevant Space (beta)`),
      description: template(`Not Relevant Space (beta)`),
      section: "product",
      icon: "bi-hand-thumbs-down",
    },
    new_funding: {
      summary: template(
        `Funding round <% if (investor_type !== "[investor_type]") {%> from <%= investor_type %> <%} else {%> <%}%> <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      description: template(
        `Funding round <% if (investor_type !== "[investor_type]") {%> from <%= investor_type %> <%} else {%> <%}%> <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "funding",
      icon: "bi-cash-stack",
    },
    new_website_language: {
      summary: template(`Has Localized in English`),
      description: template(`Has Localized in English`),
      section: "market",
      icon: "bi-translate",
    },
    new_product_launch: {
      summary: template(`Product launch for <%= name %>`),
      description: template(`Product launch for <%= name %>`),
      section: "product",
      icon: "bi-easel",
    },
    new_product: {
      summary: template(`Product: <%= name %>`),
      description: template(`Product: <%= name %>`),
      section: "product",
      icon: "bi-easel",
    },
    new_social_network_profile: {
      summary: template(`Social profile: <%= name %>`),
      description: template(`Social profile: <%= name %>`),
      section: "product",
      icon: "bi-signal",
    },
    new_source: {
      summary: template(`Source: <%= name %>`),
      description: template(`Source: <%= name %>`),
      section: "product",
      icon: "bi-file-binary",
    },
    new_traffic: {
      summary: template(
        `More traction for: <%= name %> <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      description: template(
        `More traction for: <%= name %> <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "product",
      icon: "bi-stoplights",
    },
    founders_worked_together: {
      summary: template(`Founders worked together in the past`),
      description: template(
        `<a href="/people/<%= p1_id %>" target="_blank"><%= p1_name %></a> and <a href="/people/<%= p2_id %>" target="_blank"><%= p2_name %></a> worked together at <% if (typeof o_id === "string") { %> <a href="/organizations/<%= o_id %>" target="_blank"><%= o_name %></a> <% } else { %> <%= o_name %> <% } %>`
      ),
      section: "people",
      icon: "bi-building",
    },
    executive_person_has_left: {
      summary: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> has left`
      ),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> has left`
      ),
      section: "people",
      icon: "bi-box-arrow-right",
    },
    people_increase: {
      summary: template(`<%= count %> employees (+<%= percentage %>%)`),
      description: template(`<%= count %> employees (+<%= percentage %>%)`),
      section: "people",
      icon: "bi-diagram-3",
    },
    website_is_down: {
      summary: template(`Website is down`),
      description: template(`Website is down`),
      section: "product",
      icon: "emoji-dizzy",
    },
    soft_zombie: {
      summary: template(`Is zombie-like`),
      description: template(`Is zombie-like`),
      section: "product",
      icon: "x-octagon",
    },
    is_zombie: {
      summary: template(`Zombie`),
      description: template(`Zombie`),
      section: "funding",
      icon: "x-octagon",
    },
    domain_on_sale: {
      summary: template(`Domain is on sale`),
      description: template(`Domain is on sale`),
      section: "product",
      icon: "bi-cone-striped",
    },
    fulfill_product_not_ready: {
      summary: template(`Traffic growth fulfill passed reason`),
      description: template(`Traffic growth fulfill with passed reason`),
      section: "product",
      icon: "bi-box-arrow-right",
    },
    fulfill_founders_commitment: {
      summary: template(`All founders are now committed`),
      description: template(`All founders are now committed`),
      section: "people",
      icon: "bi-bag-check",
    },
    yc_and_ef_ready: {
      summary: template(
        `<%= inv_name %> ready <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      description: template(
        `<%= inv_name %> ready <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "funding",
      icon: "recycle",
    },
    new_pricing_page: {
      summary: template(`Added a pricing page`),
      description: template(
        `Added a pricing page <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "product",
      icon: "credit-card",
    },
    new_producthunt_banner: {
      summary: template(`Launched on ProductHunt`),
      description: template(`Launched on ProductHunt`),
      section: "product",
      icon: "bullseye",
    },
    new_people: {
      summary: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> joined`
      ),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> joined`
      ),
      section: "people",
      icon: "bi-person",
    },
    founders_relevant_backgrounds: {
      summary: template(
        `<a href="/people/<%= p_id %>" target="_blank"><% if (typeof p_name !== 'undefined') {%> <%= p_name %> <%} else {%> Person <%}%></a> (Founder) has relevant experience at <a href="/organizations/<%= o_id %>" target="_blank"><% if (typeof o_name !== 'undefined') {%> <%= o_name %> <%} else {%> Organization <%}%></a>`
      ),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><% if (typeof p_name !== 'undefined') {%> <%= p_name %> <%} else {%> Person <%}%></a> (Founder) has relevant experience at <a href="/organizations/<%= o_id %>" target="_blank"><% if (typeof o_name !== 'undefined') {%> <%= o_name %> <%} else {%> Organization <%}%></a>`
      ),
      section: "people",
      icon: "bi-person-check",
    },
    executives_relevant_backgrounds: {
      summary: template(
        `<a href="/people/<%= p_id %>" target="_blank"><% if (typeof p_name !== 'undefined') {%> <%= p_name %> <%} else {%> Person <%}%></a> (Exec) has relevant experience at <a href="/organizations/<%= o_id %>" target="_blank"><% if (typeof o_name !== 'undefined') {%> <%= o_name %> <%} else {%> Organization <%}%></a>`
      ),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><% if (typeof p_name !== 'undefined') {%> <%= p_name %> <%} else {%> Person <%}%></a> (Exec) has relevant experience at <a href="/organizations/<%= o_id %>" target="_blank"><% if (typeof o_name !== 'undefined') {%> <%= o_name %> <%} else {%> Organization <%}%></a>`
      ),
      section: "people",
      icon: "bi-person-check",
    },
    people_relevant_backgrounds: {
      summary: template(
        `<a href="/people/<%= p_id %>" target="_blank"> <% if (typeof p_name !== 'undefined') {%> <%= p_name %> <%} else {%> Person <%}%></a> (team) has relevant experience at <a href="/organizations/<%= o_id %>" target="_blank"><% if (typeof o_name !== 'undefined') {%> <%= o_name %> <%} else {%> Organization <%}%></a>`
      ),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><% if (typeof p_name !== 'undefined') {%> <%= p_name %> <%} else {%> Person <%}%></a> (team) has relevant experience at <a href="/organizations/<%= o_id %>" target="_blank"><% if (typeof o_name !== 'undefined') {%> <%= o_name %> <%} else {%> Organization <%}%></a>`
      ),
      section: "people",
      icon: "bi-person-check",
    },
    portfolio_conflict: {
      summary: template(`Possible portfolio conflict with <%= name %>`),
      description: template(`Possible portfolio conflict with <%= name %>`),
      section: "product",
      icon: "bi-clipboard-x",
    },
    no_profit: {
      summary: template(`No Profit`),
      description: template(`No Profit`),
      section: "product",
      icon: "bi-cash",
    },
    is_landing_page: {
      summary: template(`Is a Landing Page`),
      description: template(`Is a Landing Page`),
      section: "product",
      icon: "bi-gear-wide-connected",
    },
    too_recent_large_funding: {
      summary: template(`Too Recent Large Funding (>$2M <6months)`),
      description: template(`Too Recent Large Funding (>$2M <6months)`),
      section: "funding",
      icon: "bi-cash",
    },
    signal_magnitude: {
      summary: template(`<%= signal_name %>: <%= magnitude %>`),
      description: template(`<%= signal_name %>: <%= magnitude %>`),
      section: "product",
      icon: "bi-soundwave",
    },
    founders_based_in_europe: {
      summary: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> based in Europe`
      ),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> based in Europe`
      ),
      section: "people",
      icon: "bi-flag-fill",
    },
    new_joiners_analyser: {
      summary: template(`New joiners worked at TopTech or had Previous Exit`),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> (new <% if (is_founder) {%>c-level<%} else { %>employee<% }%>) 
                    <%if (has_exit_in_bio) {%>mentions an exit in their bio<%} 
                    else if (has_exit_in_experience) {%>exited <%= org_name %><%} 
                    else if (top_tech) {%> <% 
                            if (role !== "[role]") { %> <%= role %> <% } 
                            else {%> <% } %> at <% if (typeof org_id === "string") { %> <a href="/organizations/<%= org_id %>" target="_blank"><%= org_name %></a> <% } else { %> <%= org_name %> <% } %> <%= top_tech_reason %> for <%= duration %> years<%} 
                    else {%><%}%>  <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "people",
      icon: "bi-person-check",
    },
    new_tech_leadership: {
      summary: template(`New hire in tech leadership position`),
      description: template(
        `Tech leadership hire: <a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> joined as <%= role %>  <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "people",
      icon: "bi-person-check",
    },
    new_commercial_leadership: {
      summary: template(`New hire in commercial leadership position`),
      description: template(
        `Commercial leadership hire: <a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> joined as <%= role %>  <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "people",
      icon: "bi-person-check",
    },
    new_product_leadership: {
      summary: template(`New hire in product leadership position`),
      description: template(
        `Product leadership hire: <a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> joined as <%= role %>  <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "people",
      icon: "bi-person-check",
    },
    new_funding_with_follow_on: {
      summary: template(
        `Funding round from good investor announced after last decision`
      ),
      description: template(
        `Funding round from good investor announced after last decision`
      ),
      section: "funding",
      icon: "bi-cash-stack",
    },
    embedding_cluster_model: {
      summary: template(
        `Space:  <a href="/organizations?custom=true&embedding_cluster=<%= name %>&order_by=explainable_scorer" target="_blank"><%= name %></a> (beta)`
      ),
      description: template(
        `Space:  <a href="/organizations?custom=true&embedding_cluster=<%= name %>&order_by=explainable_scorer" target="_blank"><%= name %></a> (beta)`
      ),
      section: "market",
      icon: "bi-diamond",
    },
    has_ex_prefix_in_bio: {
      summary: template("Mentions of ex@ and ex- in title or bio"),
      description: template(
        `Mention of \"<%= text %>\" in <a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a>'s title/bio <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "people",
      icon: "bi-person-check",
    },
    not_landing_anymore: {
      summary: template("Website not landing anymore"),
      description: template(
        `Website not landing anymore <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "product",
      icon: "bi-gear-wide-connected",
    },
    previous_exit_in_relevant_industry: {
      summary: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> has exit in relevant industry: <a href="/organizations/<%= o_id %>" target="_blank"><%= o_name %></a>`
      ),
      description: template(
        `<a href="/people/<%= p_id %>" target="_blank"><%= p_name %></a> has exit in relevant industry: <a href="/organizations/<%= o_id %>" target="_blank"><%= o_name %></a>`
      ),
      section: "people",
      icon: "bi-box-arrow-left",
    },
    has_pivoted: {
      summary: template("Has pivoted"),
      description: template(
        `Pivoted: <%= name%> <% if (typeof ml_updated_flag !== 'undefined' && ml_updated_flag === true) {%> ${ML_UPDATED_ICON} <%} else {%> <%}%>`
      ),
      section: "market",
    },
    competitors_landscape: {
      summary: template(`Space: <%= name%>`),
      description: template(`Space: <%= name%>`),
      section: "market",
    },
  },
  aggregations: {
    worked_at_top_tech: {
      summary: template(
        `<%= p_name %> founders worked at <%= o_name %> Top Tech Companies`
      ),
      section: "people",
      counters: ["p_name", "o_name", "role"],
      grade: "good",
    },
    interesting_investors: {
      summary: template(`<%= inv_name %> interesting investors"`),
      section: "funding",
      counters: ["inv_name"],
      grade: "good",
    },
    founders_commitment_past_job: {
      summary: template(`<%= p_name %> founder/s still working on past jobs`),
      section: "people",
      counters: ["p_name", "o_name", "role"],
      grade: "warning",
    },
    founders_commitment_new_job: {
      summary: template(`<%= p_name %> founder/s have started new jobs`),
      section: "people",
      counters: ["p_name", "o_name", "role"],
      grade: "bad",
    },
    has_previous_exit: {
      summary: template(
        `<%= p_name %> founders have <%= o_name %> previous exits`
      ),
      section: "people",
      counters: ["p_name", "o_name"],
      grade: "good",
    },
    has_previous_exit_in_bio: {
      summary: template(`<%= p_name %> founders mentions an exit in their bio`),
      section: "people",
      counters: ["p_name"],
      grade: "warning",
    },
    good_education: {
      summary: template(`<%= p_name %> founder/s have good education`),
      section: "people",
      counters: ["p_name"],
      grade: "good",
    },
    top_tier_follow_on: {
      summary: template(`<%= inv_name %> Interesting Investors`),
      section: "funding",
      counters: ["inv_name"],
      grade: "good",
    },
    toptier_investor: {
      summary: template(`<%= inv_name %> TopTier VCs`),
      section: "funding",
      counters: ["inv_name"],
      grade: "good",
    },
    founders_worked_together: {
      summary: template(
        `<%= p1_name %>+ founders worked together at <%= o_name %> organizations`
      ),
      section: "people",
      counters: ["p1_name", "p2_name", "o_name"],
      grade: "good",
    },
    executive_person_has_left: {
      summary: template(`<%= p_name %> founders or executives have left`),
      section: "people",
      counters: ["p_name"],
      grade: "bad",
    },
    new_people: {
      summary: template(`<%= p_name %> employees joined`),
      section: "people",
      counters: ["p_name"],
      grade: "neutral",
    },
    founders_relevant_backgrounds: {
      summary: template(`<%= p_name %> founders have relevant experience`),
      section: "people",
      counters: ["p_name"],
      grade: "good",
    },
    executives_relevant_backgrounds: {
      summary: template(`<%= p_name %> execs have relevant experience`),
      section: "people",
      counters: ["p_name"],
      grade: "good",
    },
    people_relevant_backgrounds: {
      summary: template(`<%= p_name %> people have relevant experience`),
      section: "people",
      counters: ["p_name"],
      grade: "neutral",
    },
    founders_based_in_europe: {
      summary: template(`<%= p_name %> founders based in Europe`),
      section: "people",
      counters: ["p_name"],
      grade: "good",
    },
    new_joiners_analyser: {
      summary: template(`<%= p_name %> new joiner(s) have positive insights`),
      section: "people",
      counters: ["p_name", "o_name", "role"],
      grade: "good",
    },
    new_tech_leadership: {
      summary: template(`Tech leadership hire: <%= p_name %> persons joined`),
      section: "people",
      counters: ["p_name"],
      grade: "good",
    },
    new_commercial_leadership: {
      summary: template(
        `Commercial leadership hire: <%= p_name %> persons joined`
      ),
      section: "people",
      counters: ["p_name"],
      grade: "good",
    },
    new_product_leadership: {
      summary: template(
        `Product leadership hire: <%= p_name %> persons joined`
      ),
      section: "people",
      counters: ["p_name"],
      grade: "good",
    },
    previous_exit_in_relevant_industry: {
      summary: template(
        `<%= p_name %> founders/execs have relevant exits at <%= o_name %> orgs`
      ),
      section: "people",
      counters: ["p_name", "o_name"],
      grade: "good",
    },
  },
};
